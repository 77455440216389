/* eslint-disable no-underscore-dangle */
import { gql } from '@apollo/client';
import {
  RaFetchType,
  RaGetResponse,
  RaListParams,
  RaListResponse,
  RaQuery,
} from '../../interfaces/RaQueries';
import { CarRentalRequestType } from '../../interfaces/types.generated';
import { ApiResponse } from '../../interfaces/ApiQueries';
import getBookingsListParams from '../../utils/getBookingsListParams';

interface ListResult {
  carRentalRequests: {
    rentalRequests: Array<CarRentalRequestType>;
    overallCount: number;
  };
}

export enum BookingActions {
  CompleteBooking = 'COMPLETE_BOOKING',
  CancelBooking = 'CANCEL_BOOKING',
  ReportIncident = 'REPORT_INCIDENT',
  UpdateBookingNote = 'UPDATE_BOOKING_NOTE',
  CapturePayment = 'CAPTURE_PAYMENT',
}

const Bookings = (raFetchType: RaFetchType, params: any): RaQuery => {
  switch (raFetchType) {
    case 'GET_LIST':
      return {
        query: gql`
          query ($limit: Int, $offset: Int, $status: CarRentalRequestStatusEnum) {
            carRentalRequests(params: { limit: $limit, offset: $offset, status: $status }) {
              rentalRequests {
                id
                displayId
                status
                paymentStatus
                timeStart
                timeEnd
                createdAt
                user {
                  details {
                    firstName
                    lastName
                  }
                }
                car {
                  details {
                    registration
                  }
                  user {
                    details {
                      firstName
                      lastName
                    }
                  }
                  business {
                    name
                  }
                }
              }
              overallCount
            }
          }
        `,
        variables: getBookingsListParams(params as RaListParams),
        parseResponse: ({
          data,
        }: ApiResponse<ListResult>): RaListResponse<CarRentalRequestType> => ({
          data: data.carRentalRequests.rentalRequests,
          total: data.carRentalRequests.overallCount,
        }),
      };
    case 'GET_ONE':
      return {
        query: gql`
          query ($id: String!) {
            carRentalRequest(id: $id) {
              id
              displayId
              status
              paymentStatus
              timeStart
              timeEnd
              additionalFee
              rentalFeeSum
              rentalFeeOwner
              rentalFeeZoom
              createdAt
              acceptanceNote
              cancellationNote
              rejectionNote
              internalNote
              incidentDescription
              stripeSetupIntentId
              stripeSetupIntentUrl
              stripePaymentIntentId
              stripePaymentIntentUrl
              stripePaymentTransferId
              stripePaymentTransferUrl
              user {
                details {
                  firstName
                  lastName
                }
              }
              car {
                details {
                  registration
                }
                user {
                  details {
                    firstName
                    lastName
                  }
                }
                business {
                  name
                }
              }
            }
          }
        `,
        variables: params,
        parseResponse: ({ data }): RaGetResponse<CarRentalRequestType> => ({
          data: {
            ...data.carRentalRequest,
            rentalFeeSum: data.carRentalRequest.rentalFeeSum
              ? Number(data.carRentalRequest.rentalFeeSum) / 100
              : null,
            rentalFeeOwner: data.carRentalRequest.rentalFeeOwner
              ? Number(data.carRentalRequest.rentalFeeOwner) / 100
              : null,
            rentalFeeZoom: data.carRentalRequest.rentalFeeZoom
              ? Number(data.carRentalRequest.rentalFeeZoom) / 100
              : null,
            additionalFee: data.carRentalRequest.additionalFee
              ? Number(data.carRentalRequest.additionalFee) / 100
              : null,
          },
        }),
      };
    case 'UPDATE':
      if (params.data.action === BookingActions.CompleteBooking) {
        return {
          query: gql`
            mutation ($id: String!) {
              finishCarRentalRequest(id: $id) {
                id
              }
            }
          `,
          variables: { id: params.id },
          parseResponse: (): any => ({
            data: { id: params.id },
          }),
        };
      }
      if (params.data.action === BookingActions.CancelBooking) {
        return {
          query: gql`
            mutation ($id: String!, $note: String) {
              cancelCarRentalRequest(id: $id, note: $note) {
                id
              }
            }
          `,
          variables: { id: params.id, note: params.data.note },
          parseResponse: (): any => ({
            data: { id: params.id },
          }),
        };
      }
      if (params.data.action === BookingActions.ReportIncident) {
        return {
          query: gql`
            mutation ($id: String!, $incidentDescription: String!) {
              reportCarRentalRequestIncidentAsOwner(
                id: $id
                incidentDescription: $incidentDescription
              ) {
                id
              }
            }
          `,
          variables: { id: params.id, incidentDescription: params.data.incidentDescription },
          parseResponse: (): any => ({
            data: { id: params.id },
          }),
        };
      }
      if (params.data.action === BookingActions.UpdateBookingNote) {
        return {
          query: gql`
            mutation ($id: String!, $note: String!) {
              updateCarRentalRequestInternalNote(id: $id, note: $note) {
                id
              }
            }
          `,
          variables: { id: params.id, note: params.data.internalNote },
          parseResponse: (): any => ({
            data: { id: params.id },
          }),
        };
      }
      if (params.data.action === BookingActions.CapturePayment) {
        return {
          query: gql`
            mutation ($id: String!) {
              captureCarRentalRequestPayment(id: $id) {
                id
              }
            }
          `,
          variables: { id: params.id },
          parseResponse: (): any => ({
            data: { id: params.id },
          }),
        };
      }
      return {
        query: gql`
          query ($id: String!) {
            carRentalRequest(id: $id) {
              id
            }
          }
        `,
        variables: { id: params.id },
        parseResponse: (): any => ({
          data: {},
        }),
      };
    default:
      throw new Error(`Unknown fetch type: ${raFetchType}`);
  }
};

export default Bookings;

import { Admin as RaAdmin, DataProvider, Resource } from 'react-admin';

import { Route } from 'react-router-dom';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { defaultTheme } from './theme';
import { buildAuthProvider } from './providers/AuthProvider';
import DataProviderFactory from './providers/DataProviderFactory';
import Layout from './layout';
import { UserEdit, UserShow, UsersList } from './models/users';
import { TagCreate, TagsList } from './models/tags';
import { BusinessCreate, BusinessEdit, BusinessesList, BusinessShow } from './models/businesses';
import { InviteShow, InvitesList } from './models/invites';
import { BundleCreate, BundleEdit, BundleShow, BundlesList } from './models/bundles';
import { CodesCreate, CodesList } from './models/codes';
import { SubscriptionCreate, SubscriptionShow, SubscriptionsList } from './models/subscriptions';
import { CarCreate, CarEdit, CarShow, CarsList } from './models/cars';
import { CarmakerCreate, CarmakerEdit, CarmakerShow, CarmakersList } from './models/carmakers';
import { BookingShow, BookingsList } from './models/bookings';
import { PricingEdit, PricingShow, PricingsList } from './models/pricings';
import withFileUpload from './utils/withFileUpload';
import Reports from './models/reports/Reports';
import { CallRosterList, CallRosterShow } from './models/callroster';
import {
  StripeTestClockCreate,
  StripeTestClockList,
  StripeTestClockShow,
} from './models/stripeTestClock';
import { RenewalRequestList, RenewalRequestShow } from './models/renewalRequest';
import { OfferCreate, OfferEdit, OfferShow, OffersList } from './models/offers';
import {
  LeasingOfferCreate,
  LeasingOfferEdit,
  LeasingOfferList,
  LeasingOfferShow,
} from './models/leasingOffers';
import LeasingEnquiriesList from './models/leasingEnquiries/LeasingEnquiryList';
import LeasingEnquiriesShow from './models/leasingEnquiries/LeasingEnquiryShow';

const Admin = () => {
  const [dataProvider, setDataProvider] = useState<DataProvider>();
  useEffect(() => {
    DataProviderFactory().then((dp: DataProvider) => {
      setDataProvider(() => dp);
    });
  }, []);

  const i18nProvider = polyglotI18nProvider(() => englishMessages, 'en', {
    allowMissing: true,
  });

  if (!dataProvider) {
    return <CircularProgress color="secondary" />;
  }

  return (
    <RaAdmin
      title="Zoomev Admin"
      i18nProvider={i18nProvider}
      // authProvider={AuthProvider({ authGroups: ['Admin'] })}
      authProvider={buildAuthProvider({ authGroups: ['Admin'] })}
      dataProvider={withFileUpload(dataProvider)}
      customRoutes={[<Route path="/Reports" component={Reports} key="reports" />]}
      theme={defaultTheme}
      layout={Layout}
    >
      <Resource name="Users" list={UsersList} show={UserShow} edit={UserEdit} />
      <Resource name="Tags" list={TagsList} create={TagCreate} />
      <Resource
        name="Businesses"
        list={BusinessesList}
        show={BusinessShow}
        create={BusinessCreate}
        edit={BusinessEdit}
      />
      <Resource name="Invites" list={InvitesList} show={InviteShow} />
      <Resource
        name="Bundles"
        list={BundlesList}
        show={BundleShow}
        create={BundleCreate}
        edit={BundleEdit}
      />
      <Resource name="Codes" list={CodesList} create={CodesCreate} />
      <Resource
        name="Subscriptions"
        list={SubscriptionsList}
        show={SubscriptionShow}
        create={SubscriptionCreate}
      />
      <Resource name="RenewalRequests" list={RenewalRequestList} show={RenewalRequestShow} />
      <Resource
        name="StripeTestClock"
        list={StripeTestClockList}
        show={StripeTestClockShow}
        create={StripeTestClockCreate}
      />
      <Resource name="Cars" list={CarsList} show={CarShow} create={CarCreate} edit={CarEdit} />
      <Resource
        name="Carmakers"
        list={CarmakersList}
        show={CarmakerShow}
        create={CarmakerCreate}
        edit={CarmakerEdit}
      />
      <Resource name="Bookings" list={BookingsList} show={BookingShow} />
      <Resource name="CallRoster" list={CallRosterList} show={CallRosterShow} />
      <Resource name="Pricings" list={PricingsList} show={PricingShow} edit={PricingEdit} />
      <Resource
        name="Offers"
        list={OffersList}
        show={OfferShow}
        create={OfferCreate}
        edit={OfferEdit}
      />
      <Resource
        name="LeasingOffers"
        list={LeasingOfferList}
        show={LeasingOfferShow}
        create={LeasingOfferCreate}
        edit={LeasingOfferEdit}
      />
      <Resource name="LeasingEnquiries" list={LeasingEnquiriesList} show={LeasingEnquiriesShow} />
    </RaAdmin>
  );
};

export default Admin;

import { RaFetchType, RaParams, RaQuery } from '../interfaces/RaQueries';
import {
  Bookings,
  Bundles,
  Businesses,
  CallRoster,
  Carmakers,
  Cars,
  Codes,
  Invites,
  LeasingEnquiries,
  LeasingOffers,
  Offers,
  Pricings,
  RenewalRequests,
  StripeTestClocks,
  Subscriptions,
  Tags,
  Users,
} from './queries';

const buildQuery =
  () =>
  (raFetchType: RaFetchType, resourceName: string, params: RaParams<any>): RaQuery => {
    switch (resourceName) {
      case 'Users':
        return Users(raFetchType, params);
      case 'Tags':
        return Tags(raFetchType, params);
      case 'Businesses':
        return Businesses(raFetchType, params);
      case 'Invites':
        return Invites(raFetchType, params);
      case 'Bundles':
        return Bundles(raFetchType, params);
      case 'Codes':
        return Codes(raFetchType, params);
      case 'Subscriptions':
        return Subscriptions(raFetchType, params);
      case 'RenewalRequests':
        return RenewalRequests(raFetchType, params);
      case 'StripeTestClock':
        return StripeTestClocks(raFetchType, params);
      case 'Cars':
        return Cars(raFetchType, params);
      case 'Carmakers':
        return Carmakers(raFetchType, params);
      case 'Bookings':
        return Bookings(raFetchType, params);
      case 'CallRoster':
        return CallRoster(raFetchType, params);
      case 'Pricings':
        return Pricings(raFetchType, params);
      case 'Offers':
        return Offers(raFetchType, params);
      case 'LeasingOffers':
        return LeasingOffers(raFetchType, params);
      case 'LeasingEnquiries':
        return LeasingEnquiries(raFetchType, params);
      default:
        throw new Error(`Unknown resource name: ${resourceName}`);
    }
  };

export default buildQuery;
